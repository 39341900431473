import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQueryPrelaunch {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="bg-black text-white">

      <div
				className="bg-black"
      >
        <main>{children}</main>

			</div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
