import React from "react";

import Layout from "../components/layout-prelaunch";
import SEO from "../components/seo";

import sagan from "../images/backgrounds/Retul-road-cool.jpg";


function IndexPage() {
	return (
		<div>
			<Layout>

				<SEO
					title="Retül × SK"
					keywords={[`bikefit`, `bike`, `fit`, `hjólamátun`]}
					/>

				<div
					className="heroBanner min-h-screen bg-fixed bg-center bg-cover inset-0"
					id="top"
					>
					<div className="container mx-auto">
						<div className="w-full px-4 py-48 sm:w-1/3 sm:py-64 md:w-1/2">
							<h4 className="uppercase text-retulBlue">Retül Bike Fit</h4>
							<h2 className="uppercase
								text-4xl text-white">Vefur í vinnslu</h2>
						</div>
					</div>
				</div>

			</Layout>
		</div>
	);
}

export default IndexPage;
